import { PriceOfferStepTwoLayoutCustomerPriceSection } from '../../../../../services/api/price-offer/PriceOffer.types'
import PriceOfferPdfCustomerPriceTable from './customer-price-table/PriceOfferPdfCustomerPriceTable'
import PriceOfferPdfCustomerPriceTotals from './customer-price-table/PriceOfferPdfCustomerPriceTotals'
import React, { useMemo } from 'react'

export type PriceOfferPdfCustomerPriceProps = {
    section: PriceOfferStepTwoLayoutCustomerPriceSection
    currency: string
}

const PriceOfferPdfCustomerPrice = ({
    section: {
        data: { costs, totalPrice, totalWithoutVat, vatRate }
    },
    currency
}: PriceOfferPdfCustomerPriceProps) => {
    // Returns 'true' if all 'cost' items have a discount of '0', 'false' otherwise
    const isZeroDiscountInAllColumns = useMemo<boolean>(() => {
        return costs.every(cost => {
            return cost.discount.toString() === '0'
        })
    }, [costs])

    return (
        <div className='pdf-section-container pdf-text-right' style={{ pageBreakInside: 'avoid' }}>
            <div className='customer-price-container'>
                <div className='pdf-subtitle pdf-text-left customer-price-title'>Souhrn cenové nabídky</div>
                <PriceOfferPdfCustomerPriceTable
                    costs={costs}
                    currency={currency}
                    isZeroDiscountInAllColumns={isZeroDiscountInAllColumns}
                />
                <PriceOfferPdfCustomerPriceTotals
                    totalWithoutVat={totalWithoutVat}
                    vatRate={vatRate}
                    totalPrice={totalPrice}
                    currency={currency}
                />
            </div>
        </div>
    )
}

export default PriceOfferPdfCustomerPrice
