import {
    PriceOfferFullData,
    PriceOfferStep,
    PriceOfferStepTwoDetail
} from '../../../../services/api/price-offer/PriceOffer.types'
import { createContext, useContext } from 'react'

type PriceOfferFormContextCurrentData<Step extends PriceOfferStep = 'step_1'> = Step extends 'step_1'
    ? PriceOfferFullData
    : PriceOfferStepTwoDetail

export type UpdateStepAction = 'continue' | 'save_and_close'

export type PriceOfferFormContextState = {
    id: number
    updateStepLoading: boolean
    updateStepAction?: UpdateStepAction
    currentData: PriceOfferFullData | PriceOfferStepTwoDetail
    refetchCurrentData: () => void
}

const PriceOfferFormContext = createContext<PriceOfferFormContextState>({} as PriceOfferFormContextState)

export const { Provider: PriceOfferFormContextProvider } = PriceOfferFormContext

export const usePriceOfferContext = <PriceOfferStepValue extends PriceOfferStep = 'step_1'>() => {
    const context = useContext(PriceOfferFormContext)
    return {
        ...context,
        currentData: context.currentData as PriceOfferFormContextCurrentData<PriceOfferStepValue>
    }
}

export default PriceOfferFormContext
