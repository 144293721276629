import { formatPhoneNumber } from '../../../../../../utils/StringHelper'
import React from 'react'

export const PriceOfferPdfIdentificationItem = ({ label, data, isPhoneNumber = false }) => {
    if (!data || data.length === 0) {
        return null
    }

    return (
        <li>
            {label}: <strong>{isPhoneNumber ? formatPhoneNumber(data) : data}</strong>
        </li>
    )
}
