import { PriceOfferStepTwoLayoutCustomerPriceSection } from '../../../../../../services/api/price-offer/PriceOffer.types'
import { formatCurrency } from '../../../../../../utils/Helper'
import React, { useCallback } from 'react'

type PriceOfferPdfCustomerPriceTableRowProps = {
    item: PriceOfferStepTwoLayoutCustomerPriceSection['data']['costs'][0]
    currency: string
    isZeroDiscountInAllColumns: boolean
}

const PriceOfferPdfCustomerPriceTableRow = ({
    item,
    currency,
    isZeroDiscountInAllColumns
}: PriceOfferPdfCustomerPriceTableRowProps) => {
    // Memoizing the function to prevent unnecessary re-creations on every render
    const renderDiscountValue = useCallback((discount: string | number) => {
        if (discount.toString() === '0') {
            return '-'
        }
        return `${discount} %`
    }, [])

    return (
        <tr>
            <td className='pdf-text-left'>{item.name}</td>
            {!isZeroDiscountInAllColumns && <td>{formatCurrency(item.priceBefore, currency)}</td>}
            {!isZeroDiscountInAllColumns && <td className='pdf-font-bold'>{renderDiscountValue(item.discount)}</td>}
            <td className='pdf-text-right'>{formatCurrency(item.priceAfter, currency)}</td>
        </tr>
    )
}

export default PriceOfferPdfCustomerPriceTableRow
