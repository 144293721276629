import { PriceOfferStepTwoLayoutIdentificationSection } from '../../../../../services/api/price-offer/PriceOffer.types'
import { convertSvgToBase64 } from '../../../../../utils/Helper'
import { footerBgShadow, mailIcon, netIcon, phoneIcon } from '../icons/PriceOfferPdfIcons'
import { formatPhoneNumber } from '../../../../../utils/StringHelper'
import React, { useMemo } from 'react'

export type PriceOfferPdfFooterColors = {
    primary: string
    secondary: string
    textColor: string
    textColorSecondary: string
    backgroundColor: string
}

export type PriceOfferPdfFooterProps = {
    section: PriceOfferStepTwoLayoutIdentificationSection
    colors: PriceOfferPdfFooterColors
}

const PriceOfferPdfFooter = ({
    section: {
        data: { taker }
    },
    colors
}: PriceOfferPdfFooterProps) => {
    const svgMail = useMemo(() => convertSvgToBase64(mailIcon(colors.secondary)), [colors.secondary])
    const svgNet = useMemo(() => convertSvgToBase64(netIcon(colors.secondary)), [colors.secondary])
    const svgPhone = useMemo(() => convertSvgToBase64(phoneIcon(colors.secondary)), [colors.secondary])
    const svgBg = useMemo(() => convertSvgToBase64(footerBgShadow(colors.secondary)), [colors.secondary])

    return (
        <footer className='price-offer-pdf-footer'>
            <div className='pdf-row'>
                <div className='pdf-column footer-left-column'>
                    <div>
                        <div className='footer-prepared pdf-subtitle-secondary-bold'>
                            Nabídku zpracoval {taker.name}
                        </div>
                    </div>
                    <div className='footer-contact-info'>
                        <div className='footer-icons'>
                            <img src={svgMail} alt='Mail' className='pdf-icon-footer' width='16' height='12.5' />
                            {taker.email}
                            <img src={svgNet} alt='Net' className='pdf-icon-footer-second' width='15' height='14' />
                            <span>svetoken.cz </span>
                            <img src={svgPhone} alt='Phone' className='pdf-icon-footer-second' width='14' height='14' />
                            {formatPhoneNumber(taker.phone)}
                        </div>
                    </div>
                </div>
                <img src={svgBg} alt='Logo' className='footer-svg-background' />
                <div className='pdf-column footer-right-column'>
                    <div>
                        <div className='footer-offer pdf-subtitle-secondary-bold'>Cenová nabídka</div>
                        {/* TODO page count */}
                        {/* <div className='footer-page-info'>
                            <div className='footer-page'>strana 1/2</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default PriceOfferPdfFooter
