import { PriceOfferPdfVariant } from './PriceOfferPdf'

export type PriceOfferPdfColors = {
    primary: string
    secondary: string
    backgroundColor: string
    textColor: string
    textColorSecondary: string
    fontFamily: string
}

const getGeneralStyles = (colors: PriceOfferPdfColors, variant: PriceOfferPdfVariant, forceApplyVariant = false) => {
    return `
    ${
        !forceApplyVariant
            ? `
    @font-face {
      font-family: Arial;
      src: url("ARIAL.TTF") format("truetype");
    }
    `
            : ''
    }

    .price-offer-pdf * {
      margin: 0;
      padding: 0;
    }
    
    html.price-offer-pdf, .price-offer-pdf body {
        margin: 0;
        padding: 0;
        font-family: '${colors.fontFamily}', sans-serif;
        line-height: 1.1;
    }

    .price-offer-pdf body {
      ${
          variant === 'top-bottom-border'
              ? `
          border-top: 34px solid ${colors.secondary};
          border-bottom: 34px solid ${colors.secondary};
      `
              : ''
      }
      padding-bottom: 60px; /* push content above the footer */
    }
    
    .price-offer-pdf {
        color: ${colors.textColorSecondary};
        ${
            forceApplyVariant && variant === 'top-bottom-border'
                ? `
          border-top: 34px solid ${colors.secondary};
          border-bottom: 34px solid ${colors.secondary};
        `
                : ''
        }
         font-family: '${colors.fontFamily}', sans-serif;
    }
    
    .price-offer-pdf table {
      display: inline-table;
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      margin-bottom: 16px;
    }
    
    .price-offer-pdf table thead {
      font-weight: 500;
    }

    .price-offer-pdf table th {
      padding: 8px 4px;
      font-size: 8px;
    }
    
    .price-offer-pdf table td {
      padding: 10px 4px;
      font-size: 11px;
      font-weight: 300;
      border-bottom: 1px solid #E5E7EB;
    }
    
    .price-offer-pdf table.striped tbody tr:nth-child(even) {
      background-color: #F5F7F8;
      color: ${colors.textColorSecondary};
    }
    
    .price-offer-pdf .pdf-table-container.pdf-right {
      text-align: right;
      clear: both;
    }
    
    .price-offer-pdf .pdf-table-container.pdf-left {
      text-align: left;
    }
    
    .price-offer-pdf .pdf-table-container.pdf-center {
      text-align: center;
    }

    html.price-offer-pdf .customer-price-container {
      margin-top: 24px;
    }

    .price-offer-pdf .customer-price-container {
      background-color: ${colors.primary};
      margin-bottom: 24px;
      width: 100%;
      display: inline-block;
    }

    .price-offer-pdf .customer-price-title {
      padding: 20px;
    }

    .price-offer-pdf .customer-price-table {
      width: 95%;
      margin-right: 20px;
      text-align: center;
    }
    
    .price-offer-pdf .customer-price-table thead {
      border-bottom: 1px solid #E5E7EB; 
    }
    
    .price-offer-pdf .customer-price-table tbody td {
      padding: 12px 5px;
      border-bottom: 1px solid #E5E7EB;
    }
    
    .price-offer-pdf .customer-price-totals {
      margin-top: 10px;
      margin-right: 20px;
      width: 50%;
    }
    
    .price-offer-pdf .customer-price-totals th {
      padding: 5px;
    }
    
    .price-offer-pdf .customer-price-totals td {
      text-align: right;
      border: 0;
      padding: 5px;
    }
    
    .price-offer-pdf .pdf-bg-primary {
      background-color: ${colors.primary};
      border-radius: 5px;
      overflow: hidden; 
    }
    
    .price-offer-pdf .pdf-bg-secondary {
      background-color: ${colors.secondary};
    }
    
    .price-offer-pdf .pdf-text-secondary {
      color: ${colors.secondary};
    }
    
    .price-offer-pdf .pdf-bg-white {
        background-color: white;
    }

    .pdf-identification-divider {
      background-color: ${colors.primary};
      height: 20px;
      overflow: hidden;
      position: relative;
    }

    .identification-svg-background {
      position: absolute;
      right: 3.8rem;
      top: -12rem;
      width: 100%;
    }
`
}

const getPdfStyles = (colors: PriceOfferPdfColors, variant: PriceOfferPdfVariant) => {
    return `
    .price-offer-pdf .pdf-container {
      box-sizing: border-box;
      margin: 0 auto;
    }
    
    .price-offer-pdf .pdf-section-divider {
      border-top: 1px solid #D1D5DB;
    }
    
    .price-offer-pdf .pdf-section-container {
      padding: 16px 28px 0 28px;
    }

    .price-offer-pdf .pdf-text-section-container {
      padding: 26px 28px 0 28px;
    }

    .price-offer-pdf .pdf-section-identification-container {
      ${
          variant === 'variant-bg'
              ? `
            background-color: ${colors.primary};
            margin-bottom: 20px;
    `
              : 'padding: 30px 28px 30px 28px; background-color: white;'
      }
    }
    
    .price-offer-pdf .pdf-section-left-container {
      padding-left: 28px;
  
    }
    
    .price-offer-pdf .pdf-section-right-container {
      padding-right: 28px;
    }

    .price-offer-pdf .pdf-text-title {
      margin-top: 10px;
    }
`
}

const getFontStyles = (colors: PriceOfferPdfColors) => {
    return `
    .price-offer-pdf .pdf-title {
      font-size: 27px;
      line-height: 28px;
      text-align: right;
      font-weight: 600;
      color: ${colors.secondary};
    }
    
    .price-offer-pdf .pdf-subtitle {
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      color: ${colors.secondary};
    }

    .price-offer-pdf .pdf-subtitle-normal {
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      padding-bottom: 10px;
    }

    .price-offer-pdf .pdf-subtitle-secondary {
      font-size: 11px;
      line-height: 11px;
      font-weight: 300;
    }

    .price-offer-pdf .pdf-subtitle-secondary-bold {
      font-size: 11px;
      line-height: 11px;
      font-weight: 600;
    }

    .price-offer-pdf .pdf-text-large {
      font-size: 13px;
      font-weight: 700;
      line-height: 13px;
      color: ${colors.secondary};
    }

    .price-offer-pdf .pdf-text-large-normal {
      font-size: 13px;
      line-height: 16px;
      font-weight: 700;
      padding-bottom: 5px;
    }
    
    .price-offer-pdf .pdf-text {
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
      padding: 5px 0;
      list-style: none;
    }

    .price-offer-pdf .pdf-text-attributes {
      font-size: 11px;
      line-height: 14px;
      font-weight: 300;
      padding: 7px 0;
      list-style: none;
    }

    .price-offer-pdf .pdf-text-ul {
      font-size: 12px;
      font-weight: 300;
    }

    .price-offer-pdf .pdf-text-ul ul{
      padding-top: 5px;
    }

    .price-offer-pdf .pdf-text-ul li{
      margin-left: 10px;
      padding-top: 8px;
      list-style: disc;
    }

    .price-offer-pdf .pdf-text-table {
      font-size: 10px;
      font-weight: 600;
    }

    .price-offer-pdf .pdf-text-tags-bold {
      font-size: 8px;
      line-height: 10px;
      font-weight: 600;
      text-transform: uppercase;
      margin-right: 5px;
    }

    .price-offer-pdf .pdf-text-tags {
      font-size: 10px;
      line-height: 10px;
      font-weight: 300;
    }
    
    .price-offer-pdf .pdf-text-left {
      text-align: left;
    }
    
    .price-offer-pdf .pdf-text-right {
      text-align: right;
    }
    
    .price-offer-pdf .pdf-text-center {
      text-align: center;
    }
    
    .price-offer-pdf .pdf-font-bold {
      font-weight: 700;
    }

    .price-offer-pdf .pdf-tech-spec-section {
      padding-bottom: 5px;
    }

    .price-offer-pdf .pdf-tag-section {
      margin-top: 10px;
    }

    .price-offer-pdf .pdf-tag {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 2px 5px;
      background-color: #F3F4F6;
    }

    .pdf-tag span {
      font-size: 10px;
      font-weight: 400;
    }

`
}

const getGridStyles = () => {
    return `
    .price-offer-pdf .pdf-row {
      display: table;
      width: 100%;
    }
    
    .price-offer-pdf .pdf-column {
      display: table-cell;
    }
    
    .price-offer-pdf .pdf-column.pdf-center-column-middle {
      vertical-align: middle;
    }

    .price-offer-pdf .pdf-column.left {
      text-align: left;
      width: 40%;
    }
  
    .price-offer-pdf .pdf-column.center {
        text-align: left;
        width: 35%;
    }
    
    .price-offer-pdf .pdf-column.right {
        text-align: left;
        width: 25%;
    }
`
}

const getHeaderStyles = (colors: PriceOfferPdfColors, variant: PriceOfferPdfVariant, forceApplyVariant = false) => {
    return `
    .price-offer-pdf header {
        ${variant === 'variant-bg' ? `background-color: ${colors.primary}` : ''}
        ${variant === 'top-bottom-border' && !forceApplyVariant ? 'margin-top: 38px;' : ''}
        background-color: ${colors.primary}
        z-index: 2;
        overflow: hidden;
        position: relative;
        height: 115px;
    }
    
    html.price-offer-pdf body header {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 110px;
        padding-top: 10px;
        background-color: ${colors.primary}
    }
    
    html.price-offer-pdf body {
      padding-top: 110px;
    }
    
    html:not(.price-offer-pdf) .price-offer-pdf header {
      background-color: ${colors.primary}
    }

    .price-offer-pdf .pdf-logo-container {
      width: 30%;
      padding-left: 28px;
      padding-top: 16px;
    }

    .price-offer-pdf .pdf-logo-second-container {
      width: 246px;
      height: 72px;
    }

    .pdf-logo-container {
      padding-bottom: 2px;
    }
    
    .price-offer-pdf .pdf-logo {
      max-width: 246px;
      max-height: 72px;
    }

    .pdf-logo {
     margin-top: 6px
    }

    .price-offer-pdf .pdf-icon {
      vertical-align: bottom;
      margin-right: 5px;
      margin-left: 15px;
      margin-bottom: 3px;
    }
    
    .price-offer-pdf .pdf-header-text {
      text-align: right;
      width: 70%;
      padding-bottom: 6px;
    }

    html:not(.price-offer-pdf) .price-offer-pdf .pdf-header-text {
      padding-top: 30px;
      padding-bottom: 0;
    }
    
    .price-offer-pdf .pdf-header-number {
      font-size: 11px;
      line-height: 12px;
      font-weight: 300;
      padding-top: 5px;
      display: flex;
    }

    html:not(.price-offer-pdf) .price-offer-pdf .pdf-header-number {
      margin-bottom: 16px;
      margin-left: 9.3rem;
    }

    .header-svg-background {
      position: absolute;
      right: 60px;
      top: 0
      z-index: -999;  
      width: 100%;
    }
`
}

const getFooterStyles = (colors: PriceOfferPdfColors) => {
    return `
    html.price-offer-pdf footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      color: ${colors.textColorSecondary};
      height: 60px; 
      z-index: 1;
    }
  
    html.price-offer-pdf body footer {
      padding: 0 28px 28px 28px;
    }

    .price-offer-pdf-footer {
      position: relative;
      padding: 20px 28px;
      background-color: ${colors.primary};
      overflow: hidden;
      z-index: 2;
      height: 80px;
    }

    .footer-left-column, .footer-right-column {
      position: absolute;
      top: 0px;
      display: flex;
      z-index: 3;
      transform: translateY(60%);
    }

    .footer-left-column {
      left: 20;
      width: 80%;
      display: flex;
    }

    .footer-right-column {
      right: 0;
      width: 20%; 
      text-align: right;
      margin-right: 30px;
      margin-top: 20px;
    }

    .footer-prepared {
      font-size: 10px;
    }

    .footer-page {
      font-size: 11px;
      line-height: 15px;
    }

    .price-offer-pdf .footer-page-info {
      margin-top: 5px;
    }

    .footer-page-info {
      padding-top: 5px;
    }

    .price-offer-pdf .footer-contact-info {
      position: relative;
      display: flex;
      margin-top: 5px;
    }
  
    .footer-contact-info img.pdf-icon-footer {
      vertical-align: bottom;
      margin-right: 5px;
      margin-bottom: 4px;
    }


    .footer-contact-info img.pdf-icon-footer-second {
      vertical-align: bottom;
      margin-left: 20px;
      margin-right: 5px;
      margin-bottom: 3px;
    }

    .footer-icons {
      display: inline-block;
      font-size: 11px;
      line-height: 15px;
      font-weight: 300;
      vertical-align: left; 
      padding: 5px 0;
      display: flex;
    }

    .footer-svg-background {
      position: absolute;
      left: 60;
      bottom: 0;
      z-index: -999;  
      width: 100%;
    }
`
}

const getImagesStyles = () => {
    return `
    .price-offer-pdf .pdf-images-table {
      display: table;
      width: 100%;
    }
    
    .price-offer-pdf .pdf-images-row {
      display: table-row;
    }
    
    .price-offer-pdf .pdf-images-column {
      display: table-cell;
      padding: 3px;
      vertical-align: top;
    }
    
    .price-offer-pdf .pdf-image-container div {
      width: 100%;
      height: 120px;
      background-position: center;
      background-size: cover;
    }

    .pdf-image-container img {
      width: 100%;
      height: 120px;
      border-radius: 5px;
    }
`
}

export const buildPriceOfferStyle = (
    colors: PriceOfferPdfColors,
    variant: PriceOfferPdfVariant,
    forceSetVariant: boolean = false
) => {
    const footerStyles = getFooterStyles(colors)
    const generalStyles = getGeneralStyles(colors, variant, forceSetVariant)
    const styles = `
    ${generalStyles}
    ${footerStyles}
    ${getPdfStyles(colors, variant)}
    ${getHeaderStyles(colors, variant, forceSetVariant)}
    ${getGridStyles()}
    ${getFontStyles(colors)}
    ${getImagesStyles()}
    `

    return <style dangerouslySetInnerHTML={{ __html: styles }} />
}
