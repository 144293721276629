import { PriceOfferOrderHeader, PriceOfferOrderTag } from '../../../../../../services/api/price-offer/PriceOffer.types'
import PriceOfferPdfTableHeader from './PriceOfferPdfTableHeader'
import PriceOfferPdfTableRow from './PriceOfferPdfTableRow'
import PriceOfferPdfTableTags from './PriceOfferPdfTableTags'
import React from 'react'

export type PriceOfferPdfTableHorizontalProps = {
    data: {
        idOrderConnection: number
        productName: string
        headers: PriceOfferOrderHeader[]
        tags: PriceOfferOrderTag[]
        attributes: [
            [
                {
                    attribute: string
                    value: string | number
                }
            ]
        ]
    }
    flatHeaders: (headers: PriceOfferOrderHeader[]) => PriceOfferOrderHeader[]
}

const PriceOfferPdfTableHorizontal = ({ data, flatHeaders }: PriceOfferPdfTableHorizontalProps) => (
    <>
        <div className='pdf-tech-spec-section'>
            <div className='pdf-text-large-normal'>Technická specifikace</div>
            <PriceOfferPdfTableTags tags={data.tags} />
        </div>
        <div className='pdf-row'>
            <div className='pdf-column'>
                <table>
                    <thead>
                        <PriceOfferPdfTableHeader headers={flatHeaders(data.headers)} />
                    </thead>
                    <tbody>
                        {data.attributes.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <PriceOfferPdfTableRow row={row} />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
)

export default PriceOfferPdfTableHorizontal
