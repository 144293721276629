import {
    PriceOfferOrderHeader,
    PriceOfferStepTwoLayoutTablesSection
} from '../../../../../services/api/price-offer/PriceOffer.types'
import PriceOfferPdfTableHorizontal from './table/PriceOfferPdfTableHorizontal'
import PriceOfferPdfTableVertical from './table/PriceOfferPdfTableVertical'
import React, { useCallback, useMemo } from 'react'

export type PriceOfferPdfTablesProps = {
    section: PriceOfferStepTwoLayoutTablesSection
}

const verticalTablePixelsSwitch = 850

const PriceOfferPdfTables = ({ section: { data } }: PriceOfferPdfTablesProps) => {
    const headerWidthWithColumnPixels = useMemo<number>(() => {
        return data.headers.reduce((width, { columnWidthPixels }) => {
            return width + columnWidthPixels
        }, 0)
    }, [data.headers])

    const showVerticalTable = useMemo<boolean>(() => {
        return headerWidthWithColumnPixels >= verticalTablePixelsSwitch
    }, [headerWidthWithColumnPixels])

    const flatHeaders = useCallback((headers: PriceOfferOrderHeader[], parentLabels: string[] = []) => {
        let result: PriceOfferOrderHeader[] = []
        headers.forEach(header => {
            const newLabels = [...parentLabels, header.label]

            if (header.children && header.children.length) {
                const children = flatHeaders(header.children, newLabels)
                result = result.concat(children)
            } else {
                const newNode = {
                    ...header,
                    label: newLabels.join(' - ')
                }
                result.push(newNode)
            }
        })

        return result
    }, [])

    return (
        <div className='pdf-section-container'>
            {showVerticalTable ? (
                <PriceOfferPdfTableVertical data={data} flatHeaders={flatHeaders} />
            ) : (
                <PriceOfferPdfTableHorizontal data={data} flatHeaders={flatHeaders} />
            )}
        </div>
    )
}

export default PriceOfferPdfTables
