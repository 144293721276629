import { PriceOfferStepTwoLayoutCustomerPriceSection } from '../../../../../../services/api/price-offer/PriceOffer.types'
import PriceOfferPdfCustomerPriceTableRow from './PriceOfferPdfCustomerPriceTableRow'
import React from 'react'

type PriceOfferPdfCustomerPriceTableProps = {
    costs: PriceOfferStepTwoLayoutCustomerPriceSection['data']['costs']
    currency: string
    isZeroDiscountInAllColumns: boolean
}

const PriceOfferPdfCustomerPriceTable = ({
    costs,
    currency,
    isZeroDiscountInAllColumns
}: PriceOfferPdfCustomerPriceTableProps) => (
    <div className='pdf-table-container-price-table'>
        <table className='customer-price-table'>
            <thead className='pdf-bg-primary'>
                <tr>
                    <th className='pdf-text-left'>NÁZEV POLOŽKY</th>
                    {!isZeroDiscountInAllColumns && <th>CENA PŘED SLEVOU</th>}
                    {!isZeroDiscountInAllColumns && <th>SLEVA</th>}
                    <th className='pdf-text-right'>{isZeroDiscountInAllColumns ? 'CENA BEZ DPH' : 'CENA PO SLEVĚ'}</th>
                </tr>
            </thead>
            <tbody>
                {costs.map((item, index) => (
                    <PriceOfferPdfCustomerPriceTableRow
                        key={index}
                        item={item}
                        currency={currency}
                        isZeroDiscountInAllColumns={isZeroDiscountInAllColumns}
                    />
                ))}
            </tbody>
        </table>
    </div>
)

export default PriceOfferPdfCustomerPriceTable
