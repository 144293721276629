import { PriceOfferStepTwoLayoutTextSection } from '../../../../../services/api/price-offer/PriceOffer.types'
import { isListContentHelper } from '../../../../../utils/Helper'
import React, { useCallback } from 'react'

export type PriceOfferPdfTextProps = {
    section: PriceOfferStepTwoLayoutTextSection
    showDivider?: boolean
}

const PriceOfferPdfText = ({
    section: {
        data: { title, text }
    }
}: PriceOfferPdfTextProps) => {
    const textExists = !!text
    const isListContent = useCallback(() => {
        return textExists ? isListContentHelper(text) : false
    }, [textExists, text])

    return (
        <div className='pdf-text-section-container'>
            <div className='pdf-row'>
                <div className='pdf-column'>
                    {title?.length && <div className='pdf-subtitle-normal'>{title}</div>}
                    {/* Render as <p> or <ul> based on content */}
                    {!isListContent() && textExists && (
                        <p className='pdf-text' dangerouslySetInnerHTML={{ __html: text }} />
                    )}
                    {isListContent() && textExists && (
                        <ul className='pdf-text-ul' dangerouslySetInnerHTML={{ __html: text }} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PriceOfferPdfText
