import {
    PriceOfferLayoutSectionType,
    PriceOfferStepTwoDetail,
    PriceOfferStepTwoLayoutSection
} from '../../../../services/api/price-offer/PriceOffer.types'
import { buildPriceOfferStyle } from './PriceOfferPdf.styles'
import PriceOfferPdfCustomerPrice from './sections/PriceOfferPdfCustomerPrice'
import PriceOfferPdfDescription from './sections/PriceOfferPdfDecription'
import PriceOfferPdfFooter from './sections/PriceOfferPdfFooter'
import PriceOfferPdfHeader from './sections/PriceOfferPdfHeader'
import PriceOfferPdfIdentification from './sections/PriceOfferPdfIdentification'
import PriceOfferPdfImages from './sections/PriceOfferPdfImages'
import PriceOfferPdfTables from './sections/PriceOfferPdfTables'
import PriceOfferPdfText from './sections/PriceOfferPdfText'
import React, { ReactNode } from 'react'

export type PriceOfferPdfVariant = 'variant-svg' | 'variant-bg' | 'top-bottom-border'
export type PriceOfferPdfSectionType = PriceOfferLayoutSectionType | 'header' | 'footer'

export type PriceOfferPdfProps = {
    data: Omit<PriceOfferStepTwoDetail, 'userId' | 'presetLayoutSections' | 'layouts' | 'layoutPosition' | 'step'>
    images: { url: string; alt: string; base64: string }[]
    wrapToHtml?: boolean
    renderSections?: PriceOfferPdfSectionType[]
    currency: string
}

const VARIANT_MAPPING: Record<number, PriceOfferPdfVariant> = {
    1: 'variant-svg',
    2: 'variant-bg',
    3: 'top-bottom-border'
}

const PriceOfferPdf = ({
    data,
    wrapToHtml: enabledWrapToHtml,
    images,
    renderSections,
    currency
}: PriceOfferPdfProps) => {
    const variant = VARIANT_MAPPING[data.graphicTemplateVariant]
    const colors = {
        primary: data.graphicTemplatePrimaryColor,
        secondary: data.graphicTemplateSecondaryColor,
        textColor: data.graphicTemplateFontColor,
        textColorSecondary: data.graphicTemplateFontColorSecondary,
        fontFamily: data.graphicTemplateFont,
        backgroundColor: 'white'
    }

    const canRenderSection = (section: PriceOfferPdfSectionType) => {
        if (!renderSections || !renderSections.length) {
            return true
        }
        return renderSections.includes(section)
    }

    const renderStyles = () => {
        return buildPriceOfferStyle(colors, variant, !enabledWrapToHtml)
    }

    const wrapToHtml = (node: ReactNode) => {
        if (!enabledWrapToHtml) {
            return (
                <div>
                    {renderStyles()}
                    {node}
                </div>
            )
        }

        return (
            <html lang='cs' className='price-offer-pdf'>
                <head>
                    <title>Moje pdf 123</title>
                    <meta charSet='utf-8' />
                    <link rel='preconnect' href='https://fonts.cdnfonts.com' />
                    <link href='https://fonts.cdnfonts.com/css/arial' rel='stylesheet' />
                    <link href='https://fonts.cdnfonts.com/css/helvetica-2' rel='stylesheet' />
                    <link rel='preconnect' href='https://fonts.googleapis.com' />
                    <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='' />
                    {/* eslint-disable-next-line max-len */}
                    <link
                        href={
                            'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700' +
                            '&family=Oswald:wght@400;700&family=Poppins:wght@400;700&display=swap'
                        }
                        rel='stylesheet'
                    />
                    <link
                        href='https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'
                        rel='stylesheet'
                    />
                    {renderStyles()}
                </head>
                <body>{node}</body>
            </html>
        )
    }

    const renderHeader = () => {
        if (!canRenderSection('header')) {
            return
        }
        return <PriceOfferPdfHeader key='header-section' section={data.header} images={images} colors={colors} />
    }

    const renderFooter = () => {
        const identificationSection = data.layout.find(section => section.type === 'identification')
        if (!identificationSection || identificationSection.type !== 'identification' || !canRenderSection('footer')) {
            return null
        }
        return <PriceOfferPdfFooter key='footer-section' section={identificationSection} colors={colors} />
    }

    const renderLayout = (item: PriceOfferStepTwoLayoutSection) => {
        if (!canRenderSection(item.type)) {
            return
        }
        switch (item.type) {
            case 'identification':
                return <PriceOfferPdfIdentification key={item.id} section={item} colors={colors} />
            case 'text_1':
            case 'text_2':
            case 'text_3':
            case 'text_4':
            case 'text_5':
                return <PriceOfferPdfText key={item.id} section={item} />
            case 'description':
                return <PriceOfferPdfDescription key={item.id} section={item} />
            case 'tables':
                return <PriceOfferPdfTables key={item.id} section={item} />
            case 'banner':
                return <PriceOfferPdfImages key={item.id} section={item} images={images} />
            case 'customer_price':
                return <PriceOfferPdfCustomerPrice key={item.id} section={item} currency={currency} />
            case 'images_1':
            case 'images_2':
            case 'images_3':
            case 'images_4':
            case 'images_5':
            case 'banners_1':
            case 'banners_2':
            case 'banners_3':
            case 'banners_4':
            case 'banners_5':
                return <PriceOfferPdfImages key={item.id} section={item} images={images} />
        }
    }

    return wrapToHtml(
        <>
            {enabledWrapToHtml && renderHeader()}
            {enabledWrapToHtml && renderFooter()}
            <div className='price-offer-pdf'>
                <div className='pdf-container'>
                    {!enabledWrapToHtml && renderHeader()}
                    {data.layout.map(renderLayout)}
                    {!enabledWrapToHtml && renderFooter()}
                </div>
            </div>
        </>
    )
}

export default PriceOfferPdf
