import { PriceOfferOrderHeader, PriceOfferOrderTag } from '../../../../../../services/api/price-offer/PriceOffer.types'
import PriceOfferPdfTableRow from './PriceOfferPdfTableRow'
import React from 'react'

export type PriceOfferPdfTableVerticalProps = {
    data: {
        idOrderConnection: number
        productName: string
        headers: PriceOfferOrderHeader[]
        tags: PriceOfferOrderTag[]
        attributes: [
            [
                {
                    attribute: string
                    value: string | number
                }
            ]
        ]
    }
    flatHeaders: (headers: PriceOfferOrderHeader[]) => PriceOfferOrderHeader[]
}

const PriceOfferPdfTableVertical = ({ data, flatHeaders }: PriceOfferPdfTableVerticalProps) => (
    <div className='pdf-row' style={{ marginTop: 10 }}>
        <div className='pdf-column'>
            <table style={{ width: 'auto !important' }}>
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <div className='pdf-subtitle-normal pdf-text-left'>{data.productName}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {flatHeaders(data.headers).map((header, index) => (
                        <tr key={index}>
                            <th key={header.attributeName} className='pdf-bg-primary'>
                                <span dangerouslySetInnerHTML={{ __html: header.label }} />
                            </th>
                            {data.attributes.map((row, rowIndex) => (
                                <PriceOfferPdfTableRow row={[row[rowIndex]]} isVertical />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
)

export default PriceOfferPdfTableVertical
